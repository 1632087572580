exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-cmb-events-json-json-id-js": () => import("./../../../src/pages/events/{CmbEventsJson.jsonId}.js" /* webpackChunkName: "component---src-pages-events-cmb-events-json-json-id-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-cmb-news-json-json-id-js": () => import("./../../../src/pages/news/{CmbNewsJson.jsonId}.js" /* webpackChunkName: "component---src-pages-news-cmb-news-json-json-id-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-people-cmb-people-json-link-js": () => import("./../../../src/pages/people/{CmbPeopleJson.link}.js" /* webpackChunkName: "component---src-pages-people-cmb-people-json-link-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */)
}

